@import '../../style/abstracts/_abstracts-dir';

.lg-post-menu--paper {
  .lg-post-menu--list {
    a {
      color: $xiketic;
      text-decoration: none;
    }

    .lg-post-menu--item {
      @include setFont($font-size--md, $font-weight--regular);
    }
  }
}

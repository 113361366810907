@import '../../style/abstracts/_abstracts-dir';

.lg-scroll-field {
  .lg-scroll-field--paper {
    height: 130px !important;
    overflow-y: auto !important;

    .lg-scroll-field--item {
      display: flex;
      justify-content: space-between;
      word-wrap: break-word;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      padding-left: 10px !important;
    }
  }
}

.lg-video-form .lg-video-form--btn{
  margin-bottom: 5px !important;
}

.check-box-header{
  text-align: right;
  justify-content: space-between;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  padding-top: 5px;
  padding-right: 15px;
  font-weight: 500;
  padding-bottom: 5px;
}

.checkbox-margin-right{
  margin-right: 15px !important;
}

.checkbox-margin-left{
  margin-left: 15px !important;
}

.check-box-parent {
  display: flex !important;
}
:root {
  --color-primary: #0a58af;
  --color-black: #000000;
  --color-white: #FFFFFF;
}


// gray
$slateGray: rgb(111, 125, 140);

// black
$xiketic: rgb(11, 0, 20);

// blue
$pewterBlue: rgb(119, 160, 169);

@import '../../style/abstracts/_abstracts-dir';

.lake-games--text {
  .lake-games--text-label {
    &.lake-games--text-label-outlined {
      @include setFont($font-size--md, $font-weight--regular);

      &.lake-games--label-focused {
        color: $xiketic;
      }
    }
  }

  .lake-games--text-input {
    .lake-games--input {
      @include setFont($font-size--md, $font-weight--regular);  
    }

    &.lake-games--input-focused {
      .lake-games--input-outline {
        border-color: $xiketic;
      }
    }
  }
}

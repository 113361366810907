@import '../../style/abstracts/_abstracts-dir';

.lg-select {
  width: 100%;

  .lg-select--label {
    @include setFont($font-size--md, $font-weight--regular);
  }

  .lg-select--outlined {
    @include setFont($font-size--md, $font-weight--regular);
  }
}

.lg-select--menu {
  .lg-select--paper {
    .lg-select--list {
      .lg-select--item {
        @include setFont($font-size--md, $font-weight--regular);
      }
    }
  }
}

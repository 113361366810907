@import '../../style/abstracts/_abstracts-dir';


.lg-post {

  margin-top: 16px;

  .lg-post--header {
    background: white !important;

    a {
      color: $xiketic;
      text-decoration: none;
    }

    .lg-post--header-title {
      @include setFont($font-size--md, $font-weight--bold);

    }

    .lg-post--header-subtitle {
      @include setFont($font-size--md, $font-weight--regular);
    }
  }

  .lg-post--actions {
    background: white !important;
    display: flex;
    justify-content: space-between;
  }
}

@import '../../style/abstracts/_abstracts-dir';

.lg-trick-badge {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .lg-trick-badge--name {
    display: flex;
    align-items: center;

    >img {
      padding: 8px;
    }
  }

  .lg-trick-badge--points {
    padding: 8px;
    @include setFont($font-size--md, $font-weight--bold);
  }
}
.css-1ri6ub7-MuiPaper-root-MuiCard-root{
  max-width: unset !important;
  margin-top: 10px;
}
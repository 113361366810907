@import '../../style/abstracts/_abstracts-dir';

.lg-report-form {
  .lg-report-form--title {
    border-bottom: 1px solid black;
  }

  .lg-report-form--content {
    >div {
      padding-top: 16px;
    }
  }

  .lg-report-form--actions {
    .lg-report-form--btn {
      .lg-report-form--btn-label {
        @include setFont($font-size--md, $font-weight--regular);
        text-transform: none;
      }
    }
  }
}

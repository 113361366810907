@import '../../style/abstracts/_abstracts-dir';

.lake-games--user {
  padding: 16px;

  > div:not(:last-child) {
    margin-bottom: 32px;
  }

  .lake-games--user-header {
    @include setFont($font-size--lg, $font-weight--bold);
    border-bottom: $slateGray 1px solid;
    padding-bottom: 8px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;

    .lg-user--title {
      display: flex;
      align-items: center;

      .lg-user--name {
        word-break: break-all;
      }
    }
  }

  .lake-games--user-actions {
    display: flex;
    flex-direction: row-reverse;
  }
}

.one-sport-box {
  width: 100%;
  display: inline-block;
  margin-bottom: 10px;

  .sport-name {
    font-weight: bold;
    padding-bottom: 10px;
  }
}

@media only screen and (min-width: 200px) and (max-width: 400px) {
  .lake-games--user .lake-games--user-header {
    font-size: 25px !important;
  }
  .lake-games--user .lake-games--user-header button {
    height: 90px !important;
    margin-top: 10px !important;
  }
}

@media only screen and (min-width: 400px) and (max-width: 600px) {
  .lake-games--user .lake-games--user-header {
    font-size: 32px !important;
    margin-bottom: 10px !important;
  }
  .lake-games--user .lake-games--user-header button {
    margin-top: 10px !important;
  }
}

@media only screen and (min-width: 300px) and (max-width: 420px) {
  .user-tabs button.MuiTab-root {
    min-width: 60px !important;
    max-width: 90px !important;
    font-size: 10px;
  }
}

@media only screen and (min-width: 420px) and (max-width: 600px) {
  .user-tabs button.MuiTab-root {
    min-width: 90px !important;
    max-width: 120px !important;
    font-size: 10px;
  }
}

@media only screen and (min-width: 600px) {
  .user-tabs button.MuiTab-root {
    min-width: 100px !important;
    max-width: 200px !important;
    font-size: 11px;
  }
}

.user-tabs button.MuiTab-root {
  //min-width: 60px !important;
  //max-width: 100px !important;
  //font-size: 11px;
}

.user-tabs button.MuiTab-root {
  min-width: 140px;
  display: block !important;
  //border: 1px solid #3f51b580;
  //border-radius: 5px;
  //margin-right: 5px;
  //margin-bottom: 5px;
}

.user-paper {
  width: 100% !important;
}

.user-tabs .MuiTabs-flexContainer {
  display: flex !important;
  flex-wrap: wrap !important;
  flex: 1 1 auto !important;
  text-align: center !important;
}

.infinite-scroll-component {
  overflow: visible !important;
}

.tool__bar {
  padding: 0 !important;
  width: fit-content !important;
}

// .lg-video-form li.MuiListItem-root.MuiListItem-gutters {
//   border: 1px solid rgba(63, 81, 181, 0.5);
//   color: #3f51b5;
// }

.lg-post--actions{
  justify-content: end;
}

@import '../../style/abstracts/_abstracts-dir';

.scores-header {
  text-align: center !important;
}

.scoreboard-item-link {
  text-decoration: none;
  color: black;
}


.scores-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;

  .scores-row {
    background: #ffffff;

    .scores-count {
      text-align: center;
      padding-top: 5px;
      padding-right: 10px;
      padding-bottom: 5px;
      border-right: 1px solid black;
    }

    .scores-avatar {
      padding: 10px 20px;
    }

    .scores-name {
      padding-left: 5px;
      border-right: none;
    }

    .scores-points{
      border-left: 1px solid black;
      padding-left: 10px;
      text-align: center;
    }

  }
}
@import '../../style/abstracts/_abstracts-dir';

.lg-video-form {
  >div:not(:last-child) {
    padding-bottom: 16px;
  }

  .lg-video-form--btn {
    .lg-video-form--btn-label {
      @include setFont($font-size--md, $font-weight--regular);
      text-transform: none;
    }
  }
}

.uploadList li {
  border: 1px solid rgba(63, 81, 181, 0.5);

}

.uploadList li div{
  color: #3f51b5;
}

// make progress bar fill the whole width
.contest-progress {
    width: 100%;
}

@import '../../style/abstracts/_abstracts-dir';

.lg-dropdown {
  width: 100%;

  .lg-dropdown--label {
    @include setFont($font-size--md, $font-weight--regular);
  }

  .lg-dropdown--outlined {
    @include setFont($font-size--md, $font-weight--regular);
  }
}

.lg-dropdown--menu {
  .lg-dropdown--paper {
    .lg-dropdown--list {
      .lg-dropdown--item {
        @include setFont($font-size--md, $font-weight--regular);
      }
    }
  }
}

li.filter-item {
  margin-left: 10px;
}

@import '../../style/abstracts/_abstracts-dir';

.lg-rules {
  padding: 16px;

  > div:not(:last-child) {
    margin-bottom: 32px;
  }

  .lg-rules--header {
    @include setFont($font-size--lg, $font-weight--bold);
    border-bottom: $slateGray 1px solid;
    padding-bottom: 8px;
  }

  .lg-rules--actions {
    display: flex;
    flex-direction: row-reverse;
  }

  .lg-rules--table {
    .lg-rules--table-head {
      .lg-rules--table-row {
        .lg-rules--table-cell {
          @include setFont($font-size--md, $font-weight--bold);
        }
      }
    }

    .lg-rules--table-body {
      .lg-rules--table-row {
        .lg-rules--table-cell {
          @include setFont($font-size--md, $font-weight--regular);
        }
      }
    }
  }
}

@import '../../style/abstracts/_abstracts-dir';

.lg-search {
  padding: 16px;

  > div:not(:last-child) {
    margin-bottom: 16px;
  }

  .lg-search--header {
    @include setFont($font-size--lg, $font-weight--bold);
    border-bottom: $slateGray 1px solid;
    padding-bottom: 8px;
    display: flex;
    justify-content: space-between;
  }

  .lg-search--actions {
    display: flex;

    .lg-search--btn {
      margin-right: 8px;

      .lg-search--btn-label {
        @include setFont($font-size--md, $font-weight--regular);
        text-transform: none;
      }
    }
  }
}

.lg-search ul.MuiList-root.MuiList-padding {
  counter-reset: li;
  list-style: none;
  list-style: decimal;
  font-size: 15px;
  font-family: 'Raleway', sans-serif;
  padding: 0;
  margin-bottom: 4em;
}

.lg-search ul.MuiList-root.MuiList-padding a {
  position: relative;
  display: block;
  padding: 0.4em 0.4em 0.4em 2em;
  padding: 0.4em;
  margin: 0.5em 0;
  /* background: #93C775; */
  color: #3f51b5;
  text-decoration: none;
  -moz-border-radius: .3em;
  -webkit-border-radius: 0.3em;
  border-radius: 10em;
  transition: all .2s ease-in-out;
  border: 1px solid rgba(63, 81, 181, 0.5);
}
/*----------------------------
Media Queries
-----------------------------*/
$small: 540px;
$medium: 720px;
$large: 1000px;
$x-large: 1140px;

@mixin small {
    @media screen and (min-width: $small) { @content; }
}

@mixin medium {
    @media screen and (min-width: $medium) { @content; }
}

@mixin large {
    @media screen and (min-width: $large) { @content; }
}

@mixin x-large {
    @media screen and (min-width: $x-large) { @content; }
}

/*----------------------------
Fonts
-----------------------------*/
@mixin setFont($size, $weight) {
  font-family: 'Avenir Next', sans-serif;
  font-size: $size;
  font-weight: $weight;
}

body{margin-top:20px;
  background-color: #eee;
}

.search-result-categories>li>a {
  color: #b6b6b6;
  font-weight: 400
}

.search-result-categories>li>a:hover {
  background-color: #ddd;
  color: #555
}

.search-result-categories>li>a>.glyphicon {
  margin-right: 5px
}

.search-result-categories>li>a>.badge {
  float: right
}

.search-results-count {
  margin-top: 10px
}

.search-result-item {
  padding: 20px;
  // background-color: #fff;
  border-radius: 4px
}

.search-result-item:after,
.search-result-item:before {
  content: " ";
  display: table
}

.search-result-item:after {
  clear: both
}

.search-result-item .image-link {
  display: block;
  overflow: hidden;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px
}

@media (min-width:768px) {
  .search-result-item .image-link {
    display: inline-block;
    margin: -20px 0 -20px -20px;
    float: left;
    width: 200px
  }
}

@media (max-width:767px) {
  .search-result-item .image-link {
    max-height: 200px
  }
}

.search-result-item .image {
  max-width: 100%;
  height: 58px;
}

.search-result-item .info {
  margin-top: 2px;
  font-size: 12px;
  color: #999
}

.search-result-item .description {
  font-size: 13px
}

.search-result-item+.search-result-item {
  margin-top: 20px
}

@media (min-width:768px) {
  .search-result-item-body {
    margin-left: 200px
  }
}

.search-result-item-heading {
  font-weight: 400
}

.search-result-item-heading>a {
  color: #555
}

@media (min-width:768px) {
  .search-result-item-heading {
    margin: 0
  }
}

.search-result-item{
  border: 1px solid #B7B7B7;
  text-align: center;

}
.search-result-item a{
  text-decoration: none;
  color: black;

}
.avatar-div{
  display: flex;
}
.ava-img{
  width: 15%;
}
.ava-text{
  width: 60%;
  text-align: left;
  margin-top: 11px;
}


.comments-title {
  font-size: 16px;
  color: #262626;
  margin-bottom: 15px;
  font-family: 'Avenir Next', sans-serif;
}

.be-img-comment {
  width: 60px;
  height: 60px;
  float: left;
  margin-bottom: 15px;
}

.be-ava-comment {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.be-comment-content {
  margin-left: 60px;
}

.be-comment-content span {
  display: inline-block;
  width: 49%;
}

.be-comment-name {
  font-size: 13px;
  font-family: 'Conv_helveticaneuecyr-bold';
}

.be-comment-content a {
  color: #383b43;
  text-decoration: none;
}

.be-comment-content span {
  display: inline-block;
  width: 49%;
}

.be-comment-time {
  text-align: right;
}

.be-comment-time {
  font-size: 11px;
  color: #b4b7c1;
}

.be-comment-text {
  font-size: 13px;
    line-height: 18px;
    color: #7a8192;
    display: block;
    background: #f6f6f7;
    border: 1px solid #edeff2;
    padding: 16px 20px 20px 20px;
    margin-top: 3px;
}
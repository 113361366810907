@import '../../style/abstracts/_abstracts-dir';

.lg-edit-profile {
  .lg-edit-profile--title {
    @include setFont($font-size--md, $font-weight--regular);
    border-bottom: 1px solid black;
  }

  .lg-edit-profile--content {
    >div{
      margin-top: 8px
    }
  }

  .lg-edit-profile--actions {
    .lg-edit-profile--btn {
      .lg-edit-profile--btn-label {
        @include setFont($font-size--md, $font-weight--regular);
        text-transform: none;
      }
    }
  }
}

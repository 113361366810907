@import '../../style/abstracts/_abstracts-dir';

.lg-trick-badge {
  display: block !important;
  align-items: center !important;
  justify-content: space-between;
  width: 24% !important;
  text-align: center !important;
  float: left !important;
  margin-right: 5px;
  margin-bottom: 5px;
  height: 150px;

  .lg-trick-badge--name {
    display: block;
    align-items: center;

    > img {
      align-items: center !important;
      height: 50px;
      width: 50px;
      padding: 10px 20px;
    }
  }

  .lg-trick-badge--points {
    padding: 8px;
    @include setFont($font-size--md, $font-weight--bold);
  }
}

.medal__icon {
  margin-right: 10px !important;
}

@media only screen and (min-width: 200px) and (max-width: 600px) {
  .lg-trick-badge {
    width: 30% !important;
  }
  .lg-trick-badge--name > img {
    height: 35px !important;
    width: 35px !important;
    padding: 10px !important;
  }
}
@import '../../style/abstracts/_abstracts-dir';

.header {

  &__toolbar {
    align-items: center;
    min-height: 80px;
  }

  &__logo {
    margin-right: auto;
    margin-left: 10px;
  }

}

.block {
  display: block !important;
}

.float__left {
  float: left !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

@media only screen and (min-width: 1100px) {
  .header__large {
    display: block !important;
  }
  .header__small {
    display: none !important;
  }
}

@media only screen and (max-width: 1101px) {
  .header__small {
    display: block !important;
  }
  .header__large {
    display: none !important;
  }
}
@import '../../style/abstracts/_abstracts-dir';

.lake-games--lakes {
  padding: 16px;

  > div:not(:last-child) {
    margin-bottom: 32px;
  }

  .lake-games--lakes-header {
    @include setFont($font-size--lg, $font-weight--bold);
    border-bottom: $slateGray 1px solid;
    padding-bottom: 8px;
  }

  .lake-games--lakes-actions {
    display: flex;
    flex-direction: row-reverse;
  }
}

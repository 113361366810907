@import '../../style/abstracts/_abstracts-dir';

.lg-likes-bar {
  display: flex;
}

.like-unlike {
  width: 60px !important;
  padding: 0 !important;
}

.shaka-parent img {
  //width: 40px !important;
  //height: 40px !important;
}

.shaka-parent .shaka-single .shaka-count {
  font-weight: bold;
  position: absolute;
  z-index: 100;
  padding-left: 15px;
  bottom: 25px;
}
@import '../../style/abstracts/_abstracts-dir';

.lg-post {

  margin-top: 16px;

  .lg-post--header {
    a {
      color: $xiketic;
      text-decoration: none;
    }

    .lg-post--header-title {
      @include setFont($font-size--md, $font-weight--bold);

    }

    .lg-post--header-subtitle {
      @include setFont($font-size--md, $font-weight--regular);
    }
  }

  .lg-post--actions {
    display: flex;
    justify-content: space-between;
  }
}


.title {
  width: 100%;
  max-width: 854px;
  margin: 0 auto;
}

.caption {
  width: 100%;
  max-width: 854px;
  margin: 0 auto;
  padding: 20px 0;
}

.vid-main-wrapper {
  width: 100%;
  /* max-width: 1100px; */
  /* min-width: 440px; */
  background: #fff;
  margin: 0 auto;
}


/*  VIDEO PLAYER CONTAINER
############################### */
.vid-container {
  /* position: relative; */
  /* padding-bottom: 48%; */
  /* padding-top: 30px; */
  /* height: 0; */
  width: 68%;
  float: left;
}

.vid-container iframe,
.vid-container object,
.vid-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 360px;
}


/*  VIDEOS PLAYLIST 
############################### */
.vid-list-container {
  width: 30%;
  overflow: hidden;
  float: left;
  border-radius: 11px;
  border: 1px solid grey;
  height: auto;
  max-height: 1000px;
}

.react-video-thumbnail-image {
  height: 100%;
  width: 40%;
  position: relative;
  right: 0;
  top: 0;
  border: 1px solid grey;
}

.react-video-thumbnail-image img {
  vertical-align: middle;
  width: 100%;
  height: 100%;
  -moz-box-shadow: 10px 10px 5px #ccc;
  box-shadow: 0 0 10px;

}

.bg-grey {
  background-color: grey;
}

.vid-list-container:hover, .vid-list-container:focus {
  overflow-y: auto;
}

ol#vid-list {
  margin: 0;
  padding: 0;
  background: white;
}

ol#vid-list li {
  list-style: none;
}

ol#vid-list li {
  text-decoration: none;
  height: 25px;
  display: flex;
  padding: 10px;
}


ol#vid-list li:hover {
  background-color: #666666;
  color: white;
}

.vid-thumb {
  float: left;
  margin-right: 8px;
}

.active-vid {
  background: #3A3A3A;
}

#vid-list .desc {
  color: black;
  font-size: 13px;
  margin-top: 5px;
  margin-left: 5px;
  width: 60%;
}

.vid_frame {
  height: auto !important;
}

@media (max-width: 624px) {
  body {
    margin: 15px;
  }
  .caption {
    margin-top: 40px;
  }
  .vid-list-container {
    //padding-bottom: 20px;
  }

}

.be-comment-block {
  /* margin-bottom: 50px !important; */
  border: 1px solid #edeff2;
  border-radius: 2px;
  padding: 9px 14px;
}

.lg-post--header-title {
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  font-weight: 700;
}

@media screen and (max-width: 992px) {
  .MuiTypography-body2 {
    font-size: 0.575rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 200;
    line-height: 1.43;
    letter-spacing: 0.01071em;
  }
  .lg-post--header-title {
    font-family: 'Avenir Next', sans-serif;
    font-size: 8px;
    font-weight: 700;
  }
}

@media screen and (max-width: 600px) {
  .vid-container {
    width: 100% !important;
  }

  .vid-list-container{
    width: 100% !important;
  }

}
@import '../../style/abstracts/_abstracts-dir';

.lake-games--login {
  padding: 16px;

  > div:not(:last-child) {
    margin-bottom: 32px;
  }

  .lake-games--login-header {
    @include setFont($font-size--lg, $font-weight--bold);
    border-bottom: $slateGray 1px solid;
    padding-bottom: 8px;
  }

  .lake-games--login-actions {
    display: flex;
    flex-direction: row-reverse;
  }
}

a.google-login {
  text-decoration: none !important;
}

@media only screen and (min-width: 200px) and (max-width: 350px) {
  a.google-login > div:first-child {
    width: 120px !important;
    line-height: 16px !important;
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 350px) and (max-width: 450px) {
  a.google-login > div:first-child {
    width: 150px !important;
    line-height: 25px !important;
    font-size: 15px !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 720px) {
  a.google-login > div:first-child {
    width: 140px !important;
    line-height: 23px !important;
    font-size: 15px !important;
  }
}

@media only screen and (min-width: 965px) and (max-width: 1000px) {
  a.google-login > div:first-child {
    width: 210px !important;
    font-size: 15px !important;
  }
}

@media only screen and (min-width: 1200px) {
  a.google-login > div:first-child {
    width: 190px !important;
    font-size: 14px !important;
  }
}


@import './abstracts/_abstracts-dir';

#root {
  min-height: 100%;
}

html, body {
  margin: 0;
  height: -webkit-fill-available;
  font-family: 'Avenir Next', sans-serif;
}

.container {
  --container-width: 30rem;
  max-width: var(--container-width);
  margin: 0 auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;


  @include medium {
    --container-width: 50rem;
  }

  @include large {
    --container-width: 70rem;
  }
  
  &:first-of-type:not(.header__toolbar) {
    margin-top: 8rem;
  }

}

.MuiTab-textColorPrimary.Mui-selected{
  border: 1px solid rgba(63, 81, 181, 0.5);
}

.MuiTab-wrapper{
  font-family: 'Avenir Next', sans-serif;
}
.MuiListItem-gutters{
  padding-left: unset !important;
}
.MuiPaper-elevation3{
  box-shadow: unset !important;
}

.MuiPaper-root.MuiPaper-elevation3.MuiPaper-rounded div {
  margin-left: 13px;
}

.lg-select--menu .lg-select--paper .lg-select--list .lg-select--item{
  margin-left: 10px !important;
}
.MuiList-root div a li svg.MuiSvgIcon-root {
    margin-left: 10px;
}
.MuiList-root div li svg.MuiSvgIcon-root {
  margin-left: 10px;
}
// .MuiList-root li div div {
//   margin-left: 5px;
// }


.MuiMenu-list li{
  margin-left: 6px;
}
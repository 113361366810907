@import '../../style/abstracts/_abstracts-dir';

.lake-games--grid {
  justify-content: center;
  height: 100%;
  align-items: center;

  .lake-games--grid-tile {
  }
}

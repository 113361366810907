@import '../../style/abstracts/_abstracts-dir';

.lg--header-menu {
  .lg--header-menu-paper {
    .lg--header-menu-list {
      a {
        color: $xiketic;
        text-decoration: none;
      }

      .lg--header-menu-list-item {
        @include setFont($font-size--md, $font-weight--regular);
      }
    }
  }
}

.lg--header-menu-list-item-lg {
  padding: 10px 20px !important;
  padding-left: initial !important;
  border-radius: 5px !important;
}

.menu__btn_lg {
  float: right !important;
  padding-top: 9px !important;
}

.menu__btn_lg .header-menu-link {
  float: left !important;
  text-decoration: none !important;
  color: $xiketic !important;
}

.header-icon {
  margin-left: 10px !important;
}